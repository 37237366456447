import React from 'react';

import StandardPage from '../components/StandardPage';
import Head from '../components/Head';
// import TodoList from '../components/TodoList';

import '../style/_common.scss';

export default function todo() {
  return (
    <StandardPage>
      <Head
        title="How to Opt Out of Medicare - Direct Primary Care Startup Checklist"
        description="This page is intended to aid aspiring direct primary care physicians in opting out of Medicare and CMS. Simply identify your state, download the linked affidavit, and send it via verified mail to the address provided."
        url="/optout"
        image="/og_image.jpg"
      />
      <div className="section col ac jc" style={{ padding: '80px 4vw' }}>
        <br />
        <br />
        <h1>Medicare Opt-out For DPC</h1>
        {false && (
          <p className="readable">
            Download the linked affidavit and send it to the address provided.
          </p>
        )}
        <div className="readable">
          Consult this{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://aapsonline.org/opting-out-of-medicare-a-guide-for-physicians/">
            guide from the AAPS
          </a>{' '}
          for detailed instructions on how to opt out of Medicare.
        </div>
        <br />
        <br />

        {false && (
          <div className="readable">
            <div id="optout-listing">
              <b>AL, GA, TN</b>
              <br />
              <a href="https://www.cahabagba.com/documents/2012/02/part-b-optoutaffidavit.pdf">
                Affidavit
              </a>
              <br />
              Cahaba GBA, LLC
              <br />
              Provider Enrollment - Part B<br />
              P.O. Box 6169
              <br />
              Indianapolis, IN 46206-6168
              <br />
              <br />
              <b>
                AK, AZ, CA, HI, ID, MT, NV, ND, OR, SD, UT, WA, WY, Samoa, Guam, Northern Mariana
                Islands
              </b>
              <br />
              <a href="https://med.noridianmedicare.com/documents/10525/2052366/Opt-Out+Affidavit/7dddc5f2-3fcd-42ee-ba5b-30b7286bf201">
                Affidavit
              </a>
              <br />
              Noridian Healthcare Solutions
              <br />
              Provider Enrollment
              <br />
              P.O. Box 6703
              <br />
              Fargo, ND 58108-6703
              <br />
              <br />
              <b>
                AK, CO, DC, DE, LA, MD, MS, NJ, NM, OK, PA, TX, VA (Arlington and Fairfax, plus
                Alexandria)
              </b>
              <br />
              <a href="/documents/novitas-optout-affidavit.pdf">Affidavit</a>
              <br />
              Novitas Solutions
              <br />
              JH Provider Enrollment Services
              <br />
              P.O. Box 3095
              <br />
              Mechanicsburg, PA 17055-1813
              <br />
              <br />
              <b>CT, IL, ME, MA, MN, NH, NY, RI, VM, WI</b>
              <br />
              <a href="https://www.ngsmedicare.com/ngs/wcm/connect/7e59fd93-6fd6-4fa4-a453-7536ef269b86/392_0414_Medicare_Opt_Out_Affidavit_508.pdf?MOD=AJPERES">
                Affidavit
              </a>
              <br />
              National Government Services, Inc.
              <br />
              Part B Provider Enrollment
              <br />
              P.O. Box 7149
              <br />
              Indianapolis, IN 46207-7149
              <br />
              <br />
              <b>FL, Puerto Rico, US Virgin Islands</b>
              <br />
              <a href="https://www.cahabagba.com/documents/2012/02/part-b-optoutaffidavit.pdf">
                Affidavit
              </a>
              <br />
              First Coast Service Options
              <br />
              Provider Enrollment
              <br />
              P.O. Box 44021
              <br />
              Jacksonville, FL 32231-4021
              <br />
              <br />
              <b>NC, SC, VA (excl. Arlington and Fairfax counties, and Alexandria), WV</b>
              <br />
              <a href="https://www.palmettogba.com/Palmetto/Providers.Nsf/files/Form_Medicare_Opt-Out_Affidavit.pdf/$File/Form_Medicare_Opt-Out_Affidavit.pdf">
                Affidavit
              </a>
              <br />
              Palmetto GBA <br />
              Part B Provider Enrollment (AG-310)
              <br />
              P.O.Box 100190 <br />
              Columbia, SC 29202-3190
              <br />
              <br />
              <b>IN, IA, KS, MI, MO, NE</b>
              <br />
              <a href="https://www.wpsgha.com/wps/wcm/connect/mac/9eb2fb3a-a529-4455-a1c6-ffb4a3bf8d6d/Medicare+Opt+Out+Affidavit.pdf?MOD=AJPERES&amp;CONVERT_TO=url&amp;CACHEID=ROOTWORKSPACE.Z18_J9IA1280M86460A9F22N9C30G2-9eb2fb3a-a529-4455-a1c6-ffb4a3bf8d6d-lRQ.NLB">
                Affidavit
              </a>
              <br />
              Wisconsin Physicians Service
              <br />
              Provider Enrollment
              <br />
              P.O.Box 8248
              <br />
              Madison, WI 53708-8248 <br />
              <br />
              <b>OH, KY</b>
              <br />
              <a href="https://www.cgsmedicare.com/partb/enrollment/part_b_optout.pdf">Affidavit</a>
              <br />
              CGS Administrators LLC
              <br />
              J15 - Part B Provider Enrollment
              <br />
              PO Box 20017 <br />
              Nashville, TN 37202
            </div>
          </div>
        )}
      </div>
    </StandardPage>
  );
}
